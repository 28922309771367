<template>
  <div class="container">
    <!-- 顶部banner -->
    <div class="banner">
      <img src="../assets/birthday_map.jpg" alt="" />
      <div class="user">
          <div class="avater">
            <img v-if="users.avatar !== '' && users.avatar !== null" :src="users.avatar" :alt="users.name" />
            <div v-else class="avater_text">{{ name_text }}</div>
          </div>
          <div class="user_info">
            <label>{{ users.name }}</label>
            <p>-&nbsp;&nbsp;{{ users.dept_id_names[0] }}&nbsp;&nbsp;-</p>
          </div>
      </div>
    </div>
    <!-- 生日描述 -->
    <div class="birthday_dsc">
      <p>亲爱的金家寿星：</p>
      <p>你好呀，时光荏苒，你的生日即将来临，有什么生日愿望吗？快来动动手指写下你的生日愿望吧。</p>
      <p>本次收集的生日愿望，将在下月月会时随机抽取，抽中后即会帮助你实现哟~</p>
    </div>
    <!-- 表单提交 -->
    <div class="form_main"> 
      <van-form label-width="120" colon @submit="onSubmit">
        <van-field
          v-model="formvalue.desire"
          class="desire"
          name="desire"
          input-align="center"
          :readonly="isSubmit"
          error-message-align="center"
          placeholder="我的生日愿望"
          maxlength="10"
          :rules="[{ required: true, message: '请填写我的生日愿望' }]"
        />
        <!-- <van-field
          v-model="formvalue.executor"
          name="executor"
          label="我希望的实现人"
          placeholder="实现人"
          :rules="[{ required: true, message: '请填写我希望的实现人' }]"
        /> -->
        <div style="margin: 16px;">
          <van-button :class=" isSubmit ? 'submit_btn un_submit' : 'submit_btn'" round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>

import api from "@/fetch/api";

export default {
  data() {
    return {
      users: {
        userid: '',
        name: '',
        avatar: '',
        dept_id_names: [],
      },
      name_text: '',
      formvalue: {},
      loading: false,
      isSubmit: false
    }
  },
  props: ['activity_id'],
  created() {
    const user = JSON.parse(localStorage.getItem('userInfo'))
    if (user) {
      this.users = user
    } else {
      this.$router.replace({ name: 'Auto', query: { router: 1, activity_id: this.activity_id }});
    }
  },
  mounted() {
    if (this.users !== undefined && this.users.userid !== '') {
      this.name_text = this.users.name.substr(-2,2);
      this.getBirthdayData();
    }
  },
  methods: {
    getBirthdayData: function () {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      api.getBirthdayData({
        userid: this.users.userid,
      })
      .then((res) => {
        if (res.code === 200) {
          this.formvalue = res.result;
          if (res.result.desire !== '' && res.result.desire !== undefined) {
            this.isSubmit = true;
          }
          this.$toast.clear();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    onSubmit: function (values) {
      const _this = this;
      if (this.isSubmit) {
        this.$toast('您今年的生日愿望已经许咯~');
        return false;
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      api.birthdaySubmit({
        activity_id: this.activity_id,
        userid: this.users.userid,
        ...values
      })
      .then((result) => {
        if (result.code === 200) {
          this.$toast('提交成功');
          setTimeout(function () {
            _this.$toast.clear();
            _this.isSubmit = true;
          }, 500)
        } else {
          _this.isSubmit = false;
          this.$toast(result.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>

<style>
  .container {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
  }
  .container .banner {
    width: 100%;
    height: auto;
  }
  .container .banner img {
    width: 100%;
  }
  .container .user {
    text-align: center;
    margin-top: -40px;
  }
  .container .user .avater {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    border: 4px solid rgba(255, 255, 255, .6);
  }
  .container .user .avater_text{
    width: 100%;
    height: 100%;
    background: #1989fa;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .container .user .avater img {
    width: 100%;
    height: 100%;
  }
  .container .user .user_info {
    padding-top: 5px;
  }
  .container .user .user_info label{
    font-weight: bold;
    font-size: 18px;
  }
  .container .user .user_info p{
    font-size: 12px;
    color: rgb(119, 119, 119);
    margin: 5px 0;
  }
  .container .form_main{
    padding: 10px;
  }
  .birthday_dsc {
    font-size: 14px;
    padding: 0 25px;
    margin-top: 20px;
  }
  .birthday_dsc p{
    color: #000;
    margin: 0;
    line-height: 18px;
    text-align: justify;
    text-indent: 30px;
  }
  .birthday_dsc p:first-child{
    margin-bottom: 5px;
    text-indent: 0;
  }
  .submit_btn {
    background: rgb(243, 73, 43);
    border: 1px solid rgb(243, 73, 43);
  }
  .submit_btn.un_submit {
    background: rgb(194, 194, 194);
    border: rgb(194, 194, 194);
  }
  .desire{
    font-size: 18px;
    margin: 20px 0 30px;
    font-weight: bold;
  }
</style>